export default {
  signup: {
    title: 'Create Your account',
    text: 'By register, you agree to our <a href="{terms}" target="_blank">Terms of Service</a> and <a href="{privacy}" target="_blank">Privacy Policy</a>.',
    password: 'Password',
    telegram: 'Telegram <span>(Optional)</span>',
    referral: 'Referral Code <span>(Optional)</span>',
    help: 'Password must contain numbers (0-9), Capital and uppercase Latin letters (A-z)',
    agree: 'By agreeing to the <a href="{terms}" target="_blank">Terms of Service</a>',
    account: 'Already have an account? <a href="{signin}">Log in</a>',
    button: 'Create Account',
    bonus: 'Leave your real account and we will add 5 bonus days to your first payed plan',
    messenger: 'Messenger',
    identifier: 'Identifier',
  },
  confirm: {
    title: 'Confirm your account',
    text: "We sent a verification link to the email {email}. Click the link to verify your email. You may need to check your spam folder. Don't see it?",
    resend: 'Resend link',
    timer: 'Resending of the code will be available after: \n{timer} sec.',
    button: 'Home',
    afterReg: 'After you confirm the registration, use your Email and password to log in with <a href="{link}">0detect Browser</a> client.'
  },
  signin: {
    password: 'Password',
    button: 'Sign in',
    create: 'Create Account',
    forgot: 'Forgot Password?',
    code: 'Two-factor authentication code',
    twoFactorAuth: {
      access: 'A code has been sent to your email to confirm authorization',
      denied: 'The code you entered is incorrect. Please try to log in again.',
      wrongCode: 'Incorrect autorization code',
    },
  },
  resetPass: {
    title: 'Password Recover',
    text: 'Enter your email so we can send you a link to recover your password',
    button: 'Confirm',
  },
  changePass: {
    title: 'Change Password',
    caption: 'Enter your current password',
    text: 'Enter new Password',
    password: 'Password',
    newPass: 'New Password',
    repeatPass: 'Repeat Password',
    button: 'Confirm',
    closeAllSessions: 'Stop active sessions on other devices after changing the password',
  }
}
export default {
  index: {
    title: 'Antidetect browser for multi-accounting - 0detect Antidetect',
    description: '0detect is a security and fully focused on an antidetect browser for your safety and privacy. Join today!',
    ogTitle: 'Antidetect browser for multi-accounting - 0detect Antidetect',
    ogDescription: '0detect is a security and fully focused on an antidetect browser for your safety and privacy. Join today!',
    twitterTitle: 'Antidetect browser for multi-accounting - 0detect Antidetect',
    twitterDescription: '0detect is a security and fully focused on an antidetect browser for your safety and privacy. Join today!',
  },
  prices: {
    title: 'Subscriptions and Prices',
  },
  referral: {
    title: 'Referral Program',
  },
  downloads: {
    title: 'Download 0detect',
  },
  partners: {
    title: '0detect Partners',
  },
  blog: {
    title: '0detect Blog',
  },
  faq: {
    title: 'Frequently Asked Question',
  },
  documents: {
    terms: {
      title: 'Terms of Service'
    },
    ua: {
      title: 'User Agreement'
    },
    privacy: {
      title: 'Privacy Policy'
    },
  },
  auth: {
    signup: {
      title: 'Sign Up'
    },
    signin: {
      title: 'Sign In'
    }
  },
  account: {
    title: 'Account',
  }

}
export default {
  1: {
    name: 'Сухановa Олеся',
    text: 'Добрый день. Короткий отзыв о использовании 0detect. Я тимлид фарм-команды. Перешли на ваш антидетект с AdsPower. Невероятно нравится ваш дизайн. Что касается подмены отпечатков — вопросов нет, молодцы. Также быстро сделали функцию массового импорта аккаунтов. По сравнению с другими, процент чекпоинтов значительно ниже. Рада, что появился такой крутой украинский продукт! 👍'
  },
  2: {
    name: 'Тимофеев Павел',
    text: 'Спасибо большое за пробный период, успел затестить что мне нужно, работает браузер четко, постоянные обновы с фиксами, вы только вышли, а уже лучше чем 90% существующих на рынке, благодаря браузеру смог реализовать несколько вещей, которые не мог сделать до этого! спасибо большое, буду обязательно пользоваться!'
  },
  3: {
    name: 'Бобылёв Захар',
    text: 'Я впечатлен функциональностью и эффективностью антидетект браузера 0detect! Он обеспечивает высокий уровень защиты конфиденциальности данных и позволяет работать с большим количеством акаунтов в Meta и Twitter. Простой в использовании, с приятным дизайном. Надежный инструмент для защиты ваших онлайн-активностей.',
  },
  4: {
    name: 'Белозёров Георгий',
    text: 'Все нравится Буду окончательно переходить с другого антика и ему похожего говна которое заполонило рынок Особенно под bet365',
  },
  5: {
    name: 'Савин Богдан',
    text: 'Все работает и регистрируется. Топ вообще. На других антиках не работает, не регистрируются аккаунты вообще, именно на бет365.',
  },
  6: {
    name: 'Мясников Матвей',
    text: 'Здравствуйте! Все понравилось! Сервис на уровне, приятный интерфейс. Разобрался за пару мин! Очень порадовало что все настройки подтягиваются под прокси (вопрос остается только найти хороший.. но это дело третье). Порадовала начинка и самое главное - что протестировав профили на всех ресурсах которые меряют траст - 0detect меня удивил.'
  }
}
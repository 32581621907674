export default {
  success: {
    title: 'Успешно!',
    caption: 'Ваш тариф успешно активирован. Зайдите в браузер 0detect, чтобы использовать возможности своего аккаунта!',
    captionDeposit: 'Пополнение баланса прошло успешно.',
    button: 'Готово',
  },
  error: {
    title: 'Ошибка!',
    caption: 'Не удалось совершить оплату. Попробуйте еще раз или свяжитесь с поддержкой',
    button: 'Закрыть',
  },
}
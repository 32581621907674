export default {
  title: 'Frequently Asked Question',
  text: 'Not enough information? Here you can find answers to your questions',
  button: 'Support',
  nav: {
    general: 'General',
    profile: 'Profile',
    team: 'Team',
    api: 'API',
  },
  questions: {
    general: {
      1: {
        question: "How to get started?",
        answer: "After you create an account, you will receive an activation link to your email.  In order to get access to the features of 0detect browser, you need to top up your balance",
      },
      2: {
        question: "How can I top up my account? ",
        answer: "We accept the following payment methods: Debit and credit cards (Visa, MasterCard), Apple Pay, Google Pay, Cryptocurrencies (BTC, LTC, TRC, ETH)",
      },
      3: {
        question: "How is the exchange rate calculated?",
        answer: "If you choose to pay with Crypto, the fee is not charged, the exchange rate is provided in real time via the payment platform.\n When paying with other currencies, the fee is charged with payment platforms and depends on their own exchange rate.",
      },
      4: {
        question: "How to change a subscription plan?",
        answer: "A subscription plan may be changed even if it is active, but it requires additional payment of the difference if it is switched to a more valuable one.\n To do that, go to Settings -> click Change subscription.\n If you decide to be upgraded up to a custom pricing plan, proceed with this operation via technical support. If you have the required amount on your balance, please contact support and provide your email with the desired package. \n Please note that downgrading is possible only after the expiration of your current subscription. Refill your balance and choose the most suitable one. All the existing resources that exceed the chosen type of a subscription, will be removed.",
      },
    },
    profile: {
      1: {
        question: "How to transfer a profile?",
        answer: "Choose profiles on the sheet, then select Transfer profiles option in the menu. If needed, you can also transfer them along with a proxy.",
      },
      2: {
        question: "How to delete a profile? ",
        answer: "Click on three dots next to the profile and select Delete in a drop-down menu. Press Confirm in a pop-up window in order to save changes.",
      },
      3: {
        question: "How to add cookies?",
        answer: "0detect allows you to download a document in .txt or .json format. Drag and drop your file on the Import cookies screen, or simply specify references separated by a comma or line by line.",
      },
    },
    team: {
      1: {
        question: "How to add a team member?",
        answer: "Select Add a new user box on the right side menu on the screen.",
      },
      2: {
        question: "How to edit a team? ",
        answer: "Go to the Team tab on the home page and select the team where you want to make changes. Here you can upload or change your Team logo, name and amount of users.",
      },
      3: {
        question: "What can I see in the Profile history?",
        answer: "The profile history shows the latest profile actions, such as: \n Profile creation\n Export profile\n Profile launch\n Profile transferring\n Dismissing a profile\n Proxy and Cookies changes\n Also, it allows you to know what profile performed these actions. At this point it becomes easier to manage and control the actions of your team members.",
      },
    },
    api: {
      1: {
        question: "What can I do with API? ",
        answer: "API allows you to automate interaction, leveraging all the data and features through a specific documented interface. While creating profiles, interlink them with the required settings (e.g.:proxy and cookies).\n The API is available for Team Plan and above.\n The API token is available only for the master account on the “Settings” tab (the token is not displayed to members team)",
      },
    }
  }
}
export default {
  title: 'Часто задаваемые вопросы',
  text: 'Недостаточно информации? Здесь вы можете найти ответы на интересующие вас вопросы ',
  button: 'Поддержка',
  nav: {
    general: 'Основные',
    profile: 'Профиль',
    team: 'Команда',
    api: 'API',
  },
  questions: {
    general: {
      1: {
        question: "Как начать работу? ",
        answer: "После создания учетной записи вы получите ссылку активации на свой электронный адрес.  Для того, чтобы получить доступ к функциям браузера 0detect, вам необходимо пополнить баланс.",
      },
      2: {
        question: "Как я могу пополнить свой счет? ",
        answer: "Мы принимаем следующие способы оплаты: Дебетовые и кредитные карты (Visa, MasterCard), Apple Pay, Google Pay, криптовалюты (BTC, LTC, TRC, ETH).",
      },
      3: {
        question: "Как рассчитывается обменный курс? ",
        answer: "Если вы выбираете оплату криптовалютой, комиссия не взимается, курс обмена предоставляется в режиме реального времени через платежную платформу.\n При оплате другими валютами комиссию снимает платежная система, в этом случае все зависит от их обменного курса.",
      },
      4: {
        question: "Как изменить тариф? ",
        answer: "Тариф может быть изменен, даже если он активен, но для этого необходимо доплатить разницу при переходе на более дорогой план.\n Для этого перейдите в Настройки → нажмите «Изменить подписку». \n Если вы решили перейти на более дорогой тарифный план, выполните эту операцию через техническую поддержку. Если на вашем балансе есть необходимая сумма, свяжитесь со службой поддержки и укажите свой e-mail с желаемым тарифом.\n Переход на новый тарифный план возможен только после истечения срока действия вашей текущей подписки. Пополните баланс и выберите наиболее подходящий вариант. Все существующие ресурсы, которые превышают выбранный тип подписки, будут удалены.",
      },
    },
    profile: {
      1: {
        question: "Как перенести профиль? ",
        answer: "Выберите профили в таблице, затем в меню выберите пункт «Перенести профили». При необходимости вы также можете перенести их вместе с прокси.",
      },
      2: {
        question: "Как удалить профиль? ",
        answer: "Нажмите на три точки рядом с профилем и выберите в выпадающем меню пункт «Удалить». Нажмите кнопку «Подтвердить» во всплывающем окне, чтобы сохранить изменения.",
      },
      3: {
        question: "Как добавить файлы cookie? ",
        answer: "0detect позволяет загрузить документ в формате .txt или .json. Перетащите файл на экран «Импорт файлов cookie» или укажите ссылки через запятую / с новой строки.",
      },
    },
    team: {
      1: {
        question: "Как добавить члена команды?",
        answer: "Выберите «Добавить нового пользователя» в правом боковом меню на экране в меню «Команды».",
      },
      2: {
        question: "Как редактировать команду?",
        answer: "Перейдите на вкладку «Команда» на главной странице и выберите команду, в которой вы хотите внести изменения. Здесь вы можете загрузить или изменить логотип команды, название и количество пользователей.",
      },
      3: {
        question: "Что я могу увидеть в истории профиля?",
        answer: "В истории профиля отображаются последние действия профиля, такие как:\n 1. Создание профиля,\n 2. Экспорт профиля,\n 3. Запуск профиля,\n 4. Перенос профиля,\n 5. Удаление профиля,\n 6. Изменение прокси и Cookies.",
      },

    },
    api: {
      1: {
        question: "Что я могу сделать с помощью API? ",
        answer: "API позволяет автоматизировать взаимодействие, используя все данные и возможности через специальный документированный интерфейс. Создавая профили, свяжите их с необходимыми настройками (например: proxy и cookies).\n API сейчас доступна с тарифа Team и выше.\n Токен API доступен только для мастер-аккаунта во вкладке «Настройки» (токен не отображается для членов команды).",
      }
    }
  }
}
export default {
  1: {
    name: 'Вікторія Мельниченко',
    text: "Доброго дня. Не великий відгук про використання 0detect. Я тім-лід фарм команди. Перейшли на ваш антик з ads power. Неймовірно подобається ваш дизайн. Що стосовно підміни відбитків - питань немає, молодці. Плюс швидко зробили фічу масового імпорту акаунтів. Порівняно з тим же *******, процент чекпоінтів значно нижчий. Рада, що з'явився такий крутий український продукт 👍"
  },
  2: {
    name: 'Віктор  Дмитренко',
    text: 'Дуже дякую за пробний період. Встиг протестувати все, що потрібно, і браузер працює чітко, з постійними оновленнями та виправленнями. Ви тільки вийшли, а вже кращі за 90% існуючих на ринку. Завдяки цьому браузеру зміг реалізувати кілька речей, які раніше не виходило зробити! Дякую велике, обов’язково користуватимусь!'
  },
  3: {
    name: 'Захарчук Євген',
    text: 'Я вражений функціональністю та ефективністю антидетект браузера 0detect! Він забезпечує високий рівень захисту конфіденційності даних і дозволяє працювати з великою кількістю акаунтів на Meta та Twitter. Простий у використанні, з приємним дизайном. Надійний інструмент для захисту ваших онлайн-активностей.',
  },
  4: {
    name: 'Кравчук Анатолій ',
    text: 'Мені все подобається. Остаточно переходжу з іншого антидетекта та схожого низькоякісного продукту, яким заповнений ринок, особливо для Bet365.',
  },
  5: {
    name: 'Олег Кравченко',
    text: 'Все працює і реєструється. Топовий рівень. На інших антидетектах не працює; акаунти взагалі не реєструються, особливо на Bet365.',
  },
  6: {
    name: 'Антон Пономаренко',
    text: 'Доброго дня! Мені все сподобалося. Сервіс на високому рівні, приємний інтерфейс. Розібрався за кілька хвилин! Дуже порадувало, що всі налаштування підтягнулися під проксі (питання лише у виборі хорошого, але це вже інше). Вразила функціональність, і найголовніше — після тестування профілів на всіх ресурсах, що вимірюють довіру, 0detect мене здивував.'
  }
}
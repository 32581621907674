export default {
  promo: {
    title: 'Реферальная программа',
    text: 'Приглашайте своих друзей и коллег зарегистрироваться в 0detect и получайте до 50% от их взносов. Больше пользователей - больше преимуществ!',
    button: 'Реферальная ссылка',
  },
  flow: {
    caption: "Как это работает",
    title: '0detect рефералы',
    tiles: {
      1: {
        title: 'Создайте аккаунт',
        caption: 'Создайте свой аккаунт в 0detect Browser, чтобы воспользоваться всеми его преимуществами и получить реферальную ссылку',
      },
      2: {
        title: 'Поделитесь с друзьями',
        caption: 'Отправьте ссылку своим друзьям и коллегам, чтобы они тоже зарегистрировались в 0detect, а вы получили за это бонусы',
      },
      3: {
        title: 'Получайте бонусы',
        caption: 'Когда рефералы начинают пользоваться подпиской, на ваш баланс раз в месяц будет зачисляться процент от их оплаты.',
      },
    }
  },
  benefits: {
    upperTitle: 'Преимущества',
    title: 'Преимущества реферальной программы 0detect',
    caption: 'Зарабатывайте вместе с 0detect - поделитесь своей ссылкой с друзьями, коллегами и получайте бонусы за каждую новую регистрацию. Отслеживайте свое вознаграждение в личном кабинете. Начните зарабатывать уже сегодня!',
    tiles: {
      1: {
        title: 'Высокая ставка',
        caption: '15% от каждого платежа ваших рефералов',
      },
      2: {
        title: 'Трекинг',
        caption: 'Отслеживайте рефералов в личном кабинете',
      },
      3: {
        title: 'Гарантия',
        caption: 'Гарантия своевременных выплат',
      },
    }
  }
}
export default {
  signup: {
    title: "Створити обліковий запис",
    text: 'Реєструючись, ви погоджуєтеся з нашими <a href="{terms}" target="_blank">Умовами використання</a> та <a href="{privacy}" target="_blank">Політикою конфіденційності</a>',
    password: 'Пароль',
    telegram: "Telegram",
    referral: "Реферальний код <span>(необов'язково)</span>",
    help: 'Пароль має містити цифри (0-9), великі та прописні латинські літери (A-z).',
    agree: 'Погоджуюсь із <a href="{terms}" target="_blank">Загальними положеннями та умовами</a>',
    account: 'У вас уже є обліковий запис? <a href="{signin}">Увійти</a>',
    button: 'Створити обліковий запис',
    bonus: 'Залиште свій реальний обліковий запис, і ми додамо 5 бонусних днів до вашого першого оплаченого плану',
    messenger: 'Мессенджер',
    identifier: 'Ідентифікатор',
  },
  confirm: {
    title: 'Підтвердіть обліковий запис',
    text: "Ми надіслали посилання для підтвердження на пошту {email}. Натисніть на посилання, щоб підтвердити свій обліковий запис. Вам може знадобитися перевірити папку «Спам». Не бачите?",
    resend: 'Надіслати посилання повторно',
    timer: 'Повторне надсилання коду буде доступним через: \n{timer} сек.',
    button: 'Головна',
    afterReg: 'Після підтвердження реєстрації використовуйте свою адресу електронної пошти та пароль для входу за допомогою клієнта <a href="{link}">браузера 0detect</a>.'
  },
  signin: {
    пароль: 'Пароль',
    button: 'Увійти',
    create: 'Створити акаунт',
    forgot: 'Забули пароль?',
    code: "Код двофакторної аутентифікації",
    twoFactorAuth: {
      access: 'Для підтвердження авторизації на ваш емейл було надіслано код',
      denied: 'Введений код неправильний. Будь ласка, повторіть спробу авторизації.',
      wrongCode: 'Неправильний код авторизації',
    },
  },
  resetPass: {
    title: 'Відновлення пароля',
    text: 'Введіть адресу електронної пошти, щоб ми могли надіслати вам посилання для відновлення пароля',
    button: 'Підтвердити',
  },
  changePass: {
    title: 'Змінити пароль',
    caption: 'Введіть поточний пароль',
    text: 'Введіть новий пароль',
    password: 'Пароль',
    newPass: 'Новий пароль',
    repeatPass: 'Повторіть пароль',
    button: 'Підтвердити',
    closeAllSessions: 'Зупинити активні сеанси на інших пристроях після зміни пароля',
  }
}
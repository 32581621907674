export default {
  promo: {
    title: 'Реферальна програма',
    text: 'Запрошуйте своїх друзів та колег зареєструватися в 0detect і отримуйте до 50% від їхніх внесків. Більше користувачів - більше переваг!',
    button: 'Реферальне посилання',
  },
  flow: {
    caption: "Як це працює",
    title: '0detect реферали',
    tiles: {
      1: {
        title: 'Створіть акаунт',
        caption: 'Створіть свій акаунт в 0detect Browser, щоб скористатися всіма його перевагами та отримати реферальне посилання',
      },
      2: {
        title: 'Поділіться з друзями',
        caption: 'Надішліть посилання своїм друзям і колегам, щоб вони теж зареєструвалися в 0detect, а ви отримали за це бонуси',
      },
      3: {
        title: 'Отримуйте бонуси',
        caption: 'Коли реферали починають користуватися підпискою, на ваш баланс раз на місяць зараховуватиметься відсоток від їхньої оплати.',
      },
    }
  },
  benefits: {
    upperTitle: 'Переваги',
    title: 'Переваги реферальної програми 0detect',
    caption: 'Заробляйте разом з 0detect - поділіться своїм посиланням з друзями, колегами та отримуйте бонуси за кожну нову реєстрацію. Відстежуйте свою винагороду в особистому кабінеті. Почніть заробляти вже сьогодні!',
    tiles: {
      1: {
        title: 'Висока ставка',
        caption: '15% від кожної операції ваших рефералів',
      },
      2: {
        title: 'Трекінг',
        caption: 'Відстежуйте рефералів в особистому кабінеті',
      },
      3: {
        title: 'Гарантія',
        caption: 'Гарантовані своєчасні виплати',
      },
    }
  }
}
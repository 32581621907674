export default {
  title: 'Скачать 0detect',
  caption: 'Скачивая 0detect, вы принимаете Условия использования, Пользовательское соглашение, Политику конфиденциальности.',
  win: {
    title: 'Windows',
    caption: '<p>- Windows 10 и выше</p> <p>- 2 ГБ оперативной памяти для 64-разрядной системы</p> <p>- Не менее 2 ГБ свободного дискового пространства</p>',
    button: 'Скачать 64 bit',
  },
  mac: {
    title: 'MacOS',
    caption: '<p>- MacOS Big Sur 11.0 и выше</p> <p>- Поддержка Intel и Apple Silicon</p>',
    buttonSecondary: 'Скачать (Intel)',
    button: 'Скачать (ARM - Apple Silicon)',
  },
  Android: {
    title: 'Android',
    version: '',
    caption: 'Android 8 и выше',
    button: 'Скачать',
  },
  iOS: {
    title: 'iOS',
    version: '',
    caption: 'iOS 16 и выше',
    button: 'Скачать',
  },
};

export default {
  signup: {
    title: 'Создать учетную запись',
    text: 'Регистрируясь, вы соглашаетесь с нашими <a href="{terms}" target="_blank">Условиями использования</a> и <a href="{privacy}" target="_blank">Политикой конфиденциальности</a>.',
    password: 'Пароль',
    telegram: 'Telegram',
    referral: 'Реферальный код (Необязательно)',
    help: 'Пароль должен содержать цифры (0-9), заглавные и прописные латинские буквы (A-z).',
    agree: 'Принимаю <a href="{terms}" target="_blank">Условия использования</a>',
    account: 'У вас уже есть учетная запись? <a href="{signin}">Войти</a>',
    button: 'Создать аккаунт',
    bonus: 'Оставьте свой реальный аккаунт, и мы добавим 5 бонусных дней к вашему первому платному плану',
    messenger: 'Мессенджер',
    identifier: 'Идентификатор',
  },
  confirm: {
    title: 'Подтвердите учетную запись',
    text: "Мы отправили ссылку для подтверждения на почту {email}. Нажмите на ссылку, чтобы подтвердить свою учётную запись. Вам может понадобиться проверить папку «Спам». Не видите?",
    resend: 'Отправить ссылку повторно',
    timer: 'Повторная отправка кода будет доступна через: \n{timer} сек.',
    button: 'Главная',
    afterReg: 'После подтверждения регистрации используйте свой Email и пароль для входа в клиент <a href="{link}">0detect Browser</a>',
  },
  signin: {
    password: 'Пароль',
    button: 'Войти',
    create: 'Регистрация',
    forgot: 'Забыли пароль?',
    code: "Код двухфакторной аутентификации",
    twoFactorAuth: {
      access: 'Для подтверждения авторизации на ваш эмейл был отправлен код',
      denied: 'Введенный код неверен. Пожалуйста, повторите попытку авторизации.',
      wrongCode: 'Неправильный код авторизации',
    },
  },
  resetPass: {
    title: 'Восстановление пароля',
    text: 'Введите адрес электронной почты, чтобы мы могли отправить вам ссылку для восстановления пароля',
    button: 'Подтвердить',
  },
  changePass: {
    title: 'Сменить пароль',
    caption: 'Введите текущий пароль',
    text: 'Введите новый пароль',
    password: 'Пароль',
    newPass: 'Новый пароль',
    repeatPass: 'Повторите пароль',
    button: 'Подтвердить',
    closeAllSessions: 'Остановить активные сеансы на других устройствах после смены пароля',
  }
}
export default {
  title: 'Make your work fast and secure with 0detect Browser',
  text: 'Want to stay up to date with all news, discounts, promotions? Sign up for our newsletter and be the first to receive the latest information',
  button: 'Subscribe!',
  slider: {
    1: 'Explore 0detect Browser',
    2: 'Explore 0detect Mobile App',
    3: 'Join 0detect Ecosystem today',
  },
  follow: 'Follow us on Social Media',
}
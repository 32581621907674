export default {
  balance: {
    text: 'Bulance',
    current: 'Current Balance',
    promo: 'Promocode',
    deposit: 'Deposit',
  },
  settings: {
    general: 'General Info',
    password: 'Password',
    activeSubs: 'Active Subscription',
    subsStatus: 'Subscription Status',
    subsExpire: 'Expires in {days} days',
    expired: 'Subsribtion has expired',
    referral: 'Referral Code',
    referralBtn: 'Copy',
    buttonText: 'Change',
    exit: 'Exit',
    button: 'Log Out',
  },
  changePlan: 'Choose Plan',
  empty: {
    title: 'You have no subscription',
    caption: 'Please go to the section with prices and choose the offer that suits you!',
    button: 'Choose Plan',
  }
}
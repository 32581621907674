export default {
  title: 'Зробіть свою роботу швидкою та безпечною з 0detect Browser',
  text: 'Хочете бути в курсі всіх новин, знижок, акцій? Підпишіться на нашу розсилку та отримуйте актуальну інформацію першими',
  button: 'Підписатись!',
  slider: {
    1: 'Дослідити браузер 0detect',
    2: 'Ознайомтеся з мобільним додатком 0detect',
    3: 'Приєднайтеся до 0detect Ecosystem сьогодні',
  },
  follow: 'Слідкуйте за нами в соціальних мережах',
}
export default {
  1: {
    name: 'Vanessa Murray',
    text: 'Good day. A short review on using 0detect. I’m the team lead of a farming team. We switched to your antidetect browser from AdsPower. I really love your design. As for fingerprint replacement, there are no issues—well done. Plus, you quickly developed the mass account import feature. Compared to other options, the checkpoint rate is significantly lower. I’m glad there’s such an awesome Ukrainian product! 👍'
  },
  2: {
    name: 'Kenneth Howell',
    text: 'Thank you very much for the trial period. I managed to test what I needed, and the browser works smoothly, with constant updates and fixes. You’ve just launched, but you’re already better than 90% of the existing options on the market. Thanks to this browser, I was able to implement several things I couldn’t do before! Thank you so much; I’ll definitely keep using it!'
  },
  3: {
    name: 'Tony Gaylord',
    text: 'I’m impressed by the functionality and effectiveness of the 0detect antidetect browser! It ensures a high level of data privacy protection and allows working with a large number of accounts on Meta and Twitter. It’s easy to use and has a pleasant design. A reliable tool for protecting your online activities.',
  },
  4: {
    name: 'Sid Kessler',
    text: 'I like everything. I’ll be fully switching from another antidetect browser and similar low-quality options that have flooded the market, especially for Bet365',
  },
  5: {
    name: 'Torey Sauer',
    text: 'Everything works and registers. Top-notch overall. On other antidetects, it doesn’t work; accounts don’t register at all, especially on Bet365.',
  },
  6: {
    name: 'Jovan Wiza',
    text: 'Hello! I liked everything. The service is at a high level with a nice interface. I figured it out in a few minutes! I was very pleased that all settings are adjusted according to the proxy (the only issue is finding a good one, but that’s secondary). I was impressed with the features, and most importantly, after testing profiles on all trust-measuring resources, 0detect surprised me.'
  }
}
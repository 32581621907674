export default {
  success: {
    title: 'Successfully!',
    caption: 'Your subscription has been successfully activated. Go to the 0detect browser to use the features of your account!',
    captionDeposit: 'The replenishment of the balance was successful',
    button: 'Done',
  },
  error: {
    title: 'Error!',
    caption: 'The payment could not be made. Try again or contact support',
    button: 'Cancel',
  },
}